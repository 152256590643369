import axios from "axios";

function initialState() {
  return {
    policy:{},
    variableDetails:[],
    myPolicy:{}
  };
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_POLICY(state,val){
      state.policy=val
      state.variableDetails=val?.variables?.map(item=>item.variable_detail)
    },
    SET_MY_POLICY(state,val){
      state.myPolicy=val
    }
  },
  actions: {

    
    setPolicy({ commit }, data) {
      commit('SET_POLICY',data)
    },
    setMyPolicy({commit},data){
      commit('SET_MY_POLICY',data)
    }

  

 


  },
  getters: {},
};
