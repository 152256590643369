import jwtDefaultConfig from "./jwtDefaultConfig";
import router from "@/router";
import store from "@/store";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const token = this.getToken();
        const tenantId = this.getTenantId();
        const currentCid = config.headers["cid"];
        //  console.log(tenantId +'tenant id');
        console.log(currentCid + "current cid");

        // If token is present add it to request's Authorization Header
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`;
        }
        if (
          tenantId &&
          (currentCid == "" ||
            typeof currentCid == "undefined" ||
            currentCid === null)
        ) {
          console.log(tenantId + "inside if");
          console.log(currentCid + "inside if current cid");
          config.headers["cid"] = tenantId;
        } else {
          console.log(currentCid + "inside else current cid");
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          store.dispatch("notification/resetNotifications");
          store.dispatch("app/logout").then(() => {
            router.push({ name: "login" });
          });

          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false

          //     // Update accessToken in Store
          //     this.SET_TOKEN(r.data.accessToken)
          //     this.setRefreshToken(r.data.refreshToken)
          //     this.onTokenFetched(r.data.accessToken)
          //   }).catch(err=>{
          //     console.log('err', err)
          //     router.push({ name: "login" });
          //   })
          // }
          // const retryOriginalRequest = new Promise(resolve => {
          //   this.addSubscriber(token => {
          //     // Make sure to assign accessToken according to your response.
          //     // Check: https://pixinvent.ticksy.com/ticket/2413870
          //     // Change Authorization header
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`
          //     resolve(this.axiosIns(originalRequest))
          //   })
          // })
          // return retryOriginalRequest
        }
        if (response && response.status === 403) {
          router.push("/access-denied");
        }
        if (response && response.status === 402) {
          router.push("/no-features");
        }
        if (response && response.status === 404) {
          router.push("/");
        }
        return Promise.reject(error);
      }
    );
  }

  onTokenFetched(token) {
    this.subscribers = this.subscribers.filter((callback) => callback(token));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return store.state.app[this.jwtConfig.storageTokenKeyName];
    // return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }
  getTenantId() {
    return store.state.app[this.jwtConfig.storageTenantName];
    // return localStorage.getItem(this.jwtConfig.storageTenantName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  SET_TOKEN(value) {
    store.state.app[this.jwtConfig.storageTokenKeyName] = value;
    // localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
