import { $themeBreakpoints } from "@themeConfig";
import axios from "axios";
import moment from "moment";

function initialState() {
  if (
    window.localStorage.getItem("GRC_SHARING_REQUESTED_SESSION") &&
    window.localStorage.getItem("GRC_SHARING_REQUESTED_SESSION") != null
  ) {
    let newState = {
      windowWidth: 0,
      acceptRedirectPath:true,
      ...JSON.parse(
        window.localStorage.getItem("GRC_SHARING_REQUESTED_SESSION")
      ),
    };
    newState["isSessionProvider"] = false;
    window.localStorage.removeItem("GRC_SHARING_REQUESTED_SESSION");
    return newState;
  } else {
    return {
      windowWidth: 0,
      acceptRedirectPath:true,
      isSessionProvider: true,
      shallShowOverlay: false,
      status: "",
      token: null,
      accessToken: null,
      tenantId: null,
      loggedInTime: null,
      user: {
        id: "",
        username: "",
        groups: [],
        fullname: "",
        email: "",
        is_active: false,
        is_staff: false,
        first_name: "",
        last_name: "",
        is_2fa_enabled: "",
        roles: [],
        department: null,
        profile: {
          organization: null,
          is_organization_owner: false,
          o_profile: null,
        },
        permissions: [],
      },
      isAdmin: false,
      userId: 0,
      workspaces: null,
      tenantid: null,
      currentcid: null,
      uid: null,
      tokenExpiry: null,
      twofastatus: null,
      enabled_modules: [],
      currentWorkspace: null,
      currentWorkspaceModules: [],
    };
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    workspaces: (state) => state.workspaces,
    tenantid: (state) => state.tenantid,
    tokenExpiry: (state) => state.tokenExpiry,
  },
  mutations: {
    UPDATE_SESSION_PROVIDER_STATUS(state, val) {
      state.isSessionProvider = val;
    },

    UPDATE_STATE: (state, newState) => {
      // acquire initial state
      const exludedList = ["windowWidth"];
      if (newState && newState != null && typeof newState != "undefined") {
        Object.keys(newState).forEach((key) => {
          if (exludedList.includes(key) == false) {
            state[key] = newState[key];
          }
        });
      }
    },

    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    AUTH_LOADING(state) {
      state.status = "loading";
    },
    AUTH_SUCCESS(state, data) {
      state.status = "success";
      state.loggedInTime = new Date();
      if (data.primary_tenant_id && data.primary_tenant_id !== null) {
        state.tenantId = data.primary_tenant_id;
      }
      if (
        data.user &&
        data.user !== null &&
        data.user.access_token &&
        data.user.access_token !== null
      ) {
        state.accessToken = data.user.access_token;
      }
    },

    SET_ACCEPT_REDIRECT_PATH(state, value){
      state.acceptRedirectPath = value;
    },

    auth_error(state) {
      state.status = "error";
    },
    auth_isAdmin(state) {
      state.isAdmin = true;
    },
    SET_USER_DATA(state, user) {
      state.user = user;
    },
    SET_USER_ATTRIBUTES(state, permissions) {
      state.user.permissions = permissions || null;
    },
    RESET_STATE: (state) => {
      // acquire initial state
      const exludedList = ["windowWidth"];
      const s = initialState();
      Object.keys(s).forEach((key) => {
        if (exludedList.includes(key) == false) {
          state[key] = s[key];
        }
      });
    },
    SET_WORKSPACES: (state, workspaces) => {
      state.workspaces = workspaces;
    },
    SET_TENANT_ID: (state, tenantid) => {
      state.tenantId = tenantid;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_UID: (state, payload) => {
      $cookies.set("uid", payload, null, null, process.env.VUE_APP_DOMAIN);
      state.uid = payload;
    },
    SET_CID: (state, currentcid) => {
      state.currentcid = currentcid;
    },
    SET_TOKEN_EXPIRING_TIME: (state, tokenExpiry) => {
      state.tokenExpiry = tokenExpiry;
    },
    set2Fastatus: (state, twofastatus) => {
      state.twofastatus = twofastatus;
    },
    SET_ENABLED_MODULES: (state, enabled_modules) => {
      state.enabled_modules = enabled_modules;
    },
    SET_CURRENT_WORKSPACE: (state, currentWorkspace) => {
      state.currentWorkspace = currentWorkspace;
    },
    currentWorkspace: (state, currentWorkspace) => {
      state.currentWorkspace = currentWorkspace;
    },
    currentWorkspaceModule: (state, currentWorkspaceModules) => {
      state.currentWorkspaceModules = currentWorkspaceModules;
    },
  },
  actions: {
    setToken({ commit }, payload) {
      $cookies.set("token", payload, null, null, process.env.VUE_APP_DOMAIN);
      commit("SET_TOKEN", payload);
    },
    setUID({ commit }, payload) {
      commit("SET_UID", payload);
    },

    getCurrentUser({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/current-user`,
        };
        axios(options)
          .then((resp) => {
            const { workspaces } = resp.data.data;
            const user = resp.data.data;
            console.log("getCurrentUser-> Current User", resp.data.data);
            commit("SET_WORKSPACES", workspaces);
            const currentWorkSpace = workspaces.find(
              (wrkspc) => wrkspc.tenant_uid === state.tenantId
            );
            if (currentWorkSpace) {
              commit("SET_CURRENT_WORKSPACE", currentWorkSpace);
              // commit(
              //   "SET_CURRENT_WORKSPACE_MODULES",
              //   currentWorkSpace.enabled_modules.map((wrkspc) => wrkspc.name)
              // );
              commit(
                "SET_ENABLED_MODULES",
                currentWorkSpace.enabled_modules.map((wrkspc) => wrkspc.name)
              );
            }
            commit("SET_UID", resp.data.data.user_id);
            if (user && user !== null && typeof user != "undefined") {
              commit("SET_USER_DATA", user);
            }
            commit("SET_TENANT_ID", state.tenantId);
            var expires_at = moment().add(14, "minutes");
            dispatch("setExpiry", expires_at);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        if (user.email) {
          bodyFormData.append("email", user.email);
          bodyFormData.append("password", user.password);
        } else {
          bodyFormData.append("first_auth_token", user.first_auth_token);
          bodyFormData.append("one_time_password", user.one_time_password);
        }
        //Set Status Loading
        commit("AUTH_LOADING");
        axios({
          url: `${process.env.VUE_APP_BASEURL}/auth/login`,
          data: bodyFormData,
          method: "POST",
        })
          .then((resp) => {
            let first_auth_token = resp.data.data.data
              ? resp.data.data.data.first_auth_token
              : "";
            let is_2fa_enabled = resp.data.data.data
              ? resp.data.data.data.is_2fa_enabled
              : "";
            if (first_auth_token && is_2fa_enabled) {
              // this.$router.go( '/2FA' )
              resolve(resp);
            } else {
              const { token, user, workspaces } = resp.data.data;
              user["user_id"] = user._id;
              dispatch("setToken", token);
              dispatch("setUID", user._id);
              commit("AUTH_SUCCESS", resp.data.data);
              commit("SET_USER_DATA", user);
              commit("SET_USER_ATTRIBUTES", user.permissions);
              commit("SET_WORKSPACES", workspaces);
              commit("set2Fastatus", user.is_2fa_enabled);
              commit("SET_ENABLED_MODULES", resp.data.data.enabled_modules);
              axios.defaults.headers.common.Authorization = token;
              var expires_at = moment().add(14, "minutes");
              dispatch("setExpiry", expires_at);
              resolve(resp);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setExpiry({ commit }, exp) {
      commit("SET_TOKEN_EXPIRING_TIME", exp);
    },

    checkTokenExpiry({ dispatch, state }) {
      if (moment().format() >= moment(state.tokenExpiry).format()) {
        dispatch("refreshtoken");
        // var expires_at =  moment().add(14, "minutes");
        // dispatch("setExpiry", expires_at);
      }
      // console.log(
      //   moment().format() +
      //     "/" +
      //     moment(state.tokenExpiry).format() +
      //     "its a time compare"
      // );
    },

    refreshtoken({ commit, dispatch }) {
      const BASE_URL = process.env.VUE_APP_BASEURL;
      axios({ url: `${BASE_URL}/auth/refresh-token`, method: "POST" })
        .then((resp) => {
          console.log(resp);
          const accesstoken = resp.data.data.token;
          dispatch("setToken", accesstoken);
          var expires_at = moment().add(14, "minutes");
          dispatch("setExpiry", expires_at);
        })
        .catch((err) => {});
    },

    logout({ commit, dispatch }) {
      return new Promise((resolve) => {
        dispatch("setToken", null);
        dispatch("setUID", null);
        commit("SET_TENANT_ID", null);
        commit("SET_USER_DATA", null);
        commit("SET_WORKSPACES", null);
        commit("set2Fastatus", null);
        delete axios.defaults.headers.common.Authorization;
        sessionStorage.clear();
        commit("RESET_STATE");
        resolve();
      });
    },

    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", user.firstname);
        bodyFormData.append("lastname", user.lastname);
        bodyFormData.append("company", user.company);
        bodyFormData.append("mobile", user.mobile);
        bodyFormData.append("email", user.email);
        // bodyFormData.append('password', user.password)

        commit("AUTH_LOADING");
        axios({
          url: `${process.env.VUE_APP_BASEURL}/customer/sign-up`,
          data: bodyFormData,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.status === "error") {
              reject(resp);
            } else resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    resetPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("password", user.password);
        bodyFormData.append("password_confirmation", user.password);
        bodyFormData.append("token", user.token);
        axios({
          url: `${process.env.VUE_APP_BASEURL}/forget-password/reset`,
          data: bodyFormData,
          method: "POST",
        })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    forgotPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("email", user.email);
        axios({
          url: `${process.env.VUE_APP_BASEURL}/forget-password/email`,
          data: bodyFormData,
          method: "POST",
        })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    createPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("password", user.password);
        bodyFormData.append("password_confirmation", user.password);
        bodyFormData.append("token", user.token);
        axios({
          url: `${process.env.VUE_APP_BASEURL}/firstpassword`,
          data: bodyFormData,
          method: "POST",
        })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    setCurrentUserDetails({ commit }, user) {
      commit("SET_USER_DATA", user);
    },

    getCurrentUserDepartment({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_BASEURL}/current-user`,
          method: "GET",
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    changetenant({ commit, dispatch }, payload) {
      const currentModules = [];
      payload.enabled_modules.map((item) => {
        currentModules.push(item.name);
      });
      return new Promise((resolve) => {
        commit("currentWorkspaceModule", currentModules);
        commit("currentWorkspace", payload);
        commit("SET_TENANT_ID", payload.tenant_uid);
        commit("SET_CID", payload.tenant_uid);
        resolve();
      });
    },
  },
};
