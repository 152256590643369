import { isUserLoggedIn } from "@/auth/utils";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

function checkIfUserIsVendor() {
  if (
    store.state.app.user.user_type &&
    store.state.app.user.user_type == "Vendor"
  ) {
    return true;
  } else {
    return false;
  }
}

function guardRoute(to, from, next) {
  let isAuthenticated = false;
  if (isUserLoggedIn()) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    if (to.meta.vendorAuth !== true && checkIfUserIsVendor()) {
      next("/thirdpartyrisks");
    } else {
      next();
    }
    next();
  } else {
    if ($cookies.get("token") && $cookies.get("token") != "null") {
      next("/app-switch");
    } else {
      next("/login"); // go to '/login';
    }
  }
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/NewDashboard/Dashboard.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Dashboard",
        vendorAuth: true,
        // breadcrumb: [
        //   {
        //     text: 'Stats & Graphs',
        //     active: true,
        //   },
        // ],
      },
    },

    {
      path: "/departments",
      name: "departments",
      component: () => import("@/views/Departments/main.vue"),
      beforeEnter: guardRoute,
      meta: {},
    },

    {
      path: "/review-controls",
      name: "review-controls",
      component: () => import("@/views/ReviewControls/main.vue"),
      beforeEnter: guardRoute,
      meta: {
        pageTitle: "Review Controls",
        breadcrumb: [
          {
            text: "Controls",
            active: true,
          },
        ],
      },
    },

    {
      path: "/deleted-users",
      name: "deleted-users",
      component: () => import("@/views/DeletedUsers/Listing.vue"),
      beforeEnter: guardRoute,
      props: true,
    },
    {
      path: "/usersettings",
      name: "usersettings",
      component: () => import("@/views/Settings/UsersSettings.vue"),
      beforeEnter: guardRoute,
      props: true,
    },
    {
      path: "/accountsettings",
      name: "accountsettings",
      component: () => import("@/views/Settings/AccountSettings.vue"),
      beforeEnter: guardRoute,
      props: true,
    },
    {
      path: "/organizationsettings",
      name: "organizationsettings",
      component: () => import("@/views/Settings/OrganizationSettings.vue"),
      beforeEnter: guardRoute,
      props: true,
    },
    {
      path: "/resetpasswordsettings",
      name: "resetpasswordsettings",
      component: () => import("@/views/Settings/ResetPasswordSetting.vue"),
      beforeEnter: guardRoute,
      props: true,
    },
    {
      path: "/rolesettings",
      name: "rolesettings",
      component: () => import("@/views/Settings/RoleSettings.vue"),
      beforeEnter: guardRoute,
      props: true,
    },
    {
      path: "/2fa",
      name: "2fa",
      component: () => import("@/views/Settings/TwoFactorAuth.vue"),
      beforeEnter: guardRoute,
      props: true,
    },
    // {
    //   path: "/deletedusers",
    //   name: "deletedusers",
    //   component: () => import("../DeletedUsers/Listng.vue"),
    //   beforeEnter: guardRoute,
    //   props: true,
    // },
    {
      path: "/inbox",
      name: "inbox",
      component: () => import("@/views/Inbox/InboxListing.vue"),
      beforeEnter: guardRoute,
      props: true,
    },

    {
      path: "/workspaces-empty",
      name: "workspaces-empty",
      component: () => import("@/views/NoWorkspace.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        layout: "full",
      },
    },

    {
      path: "/no-features",
      name: "no-features",
      component: () => import("@/views/NoFeature.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        layout: "full",
      },
    },
    {
      path: "/globaldashboard",
      name: "globaldashboard",
      component: () => import("@/views/GlobalDashboard/GlobalDashboard.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Global Dashboard",
        vendorAuth: true,
        // breadcrumb: [
        //   {
        //     text: 'Stats & Graphs',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/assets",
      name: "assets",
      component: () => import("@/views/Assets/AssetPage.vue"),
      beforeEnter: guardRoute,
      // meta: {
      //   pageTitle: "Asset Register",
      //   breadcrumb: [
      //     {
      //       text: "Asset Register",
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: "/assets/:id/details",
      name: "asset-details",
      component: () => import("@/views/Assets/AssetDetailsPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Asset Details",
        breadcrumb: [
          {
            text: "Assets",
            to: { name: "assets" },
          },
          {
            text: "Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assets/:id/details/create-custom-risk",
      name: "create-custom-risk",
      // component: () => import("@/views/RiskRegister/RiskAdd.vue"),
      component: () => import("@/views/RiskRegister/CustomRisk.vue"),

      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Create Custom Risk",
        breadcrumb: [
          {
            text: "Assets",
            to: { name: "assets" },
          },
          {
            text: "Asset Details",
            to: { name: "asset-details" },
          },
          {
            text: "Custom Risk",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assets/:id/edit",
      name: "asset-edit",
      component: () => import("@/views/Assets/AssetEditPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Edit Asset",
        breadcrumb: [
          {
            text: "Assets",
            to: { name: "assets" },
          },
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assets/add",
      name: "asset-add",
      component: () => import("@/views/Assets/AssetAddPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Create Asset",
        breadcrumb: [
          {
            text: "Assets",
            to: { name: "assets" },
          },
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/risk-register",
      name: "risk-register",
      component: () => import("@/views/RiskRegister/RiskRegister.vue"),
      beforeEnter: guardRoute,
      // meta: {
      //   pageTitle: "Risk Register",
      //   breadcrumb: [
      //     {
      //       text: "Risk Register",
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: "/risk-register/assets/:id/details",
      name: "risk-register-asset-details",
      component: () => import("@/views/Assets/AssetDetailsPage.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Asset Details",
        breadcrumb: [
          {
            text: "Risk Register",
            to: { name: "risk-register" },
          },
          {
            text: "Asset",
            active: true,
          },
        ],
      },
    },
    {
      path: "/risk-register/:registerId/:riskId/risk-details",
      name: "risk-details",
      // component: () => import("@/views/RiskRegister/RiskDetails.vue"),
      component: () => import("@/views/RiskRegister/RiskDetails/main.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Risk Details",
        breadcrumb: [
          {
            text: "Risk Register",
            to: { name: "risk-register" },
          },
          {
            text: "Risk",
            active: true,
          },
        ],
      },
    },

    {
      path: "/riskassessment",
      name: "riskassessment",
      component: () => import("@/views/RiskAssessment/Listing.vue"),
      beforeEnter: guardRoute,
      props: true,
      // meta: {
      //   pageTitle: "Risk Assessment",
      //   breadcrumb: [
      //     {
      //       text: "Assessment",
      //       active: true,
      //     },
      //   ],
      // },
    },

    {
      path: "/riskassessment/:id/risks",
      name: "riskassessment",
      component: () =>
        import("@/views/RiskAssessment/RiskResponseAssessment.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Risk Assessment",
        breadcrumb: [
          {
            text: "Assessment",
            to: { name: "riskassessment" },
          },
          {
            text: "Risk Assessment",
            active: true,
          },
        ],
      },
    },

    {
      path: "/tasks",
      name: "tasks",
      component: () => import("@/views/Tasks/Tasks.vue"),
      beforeEnter: guardRoute,
      meta: {
        pageTitle: "Tasks",
        breadcrumb: [
          {
            text: "User Tasks",
            active: true,
          },
        ],
      },
    },

    {
      path: "/standards",
      name: "standards",
      component: () => import("@/views/Standards/Standards.vue"),
      beforeEnter: guardRoute,
      meta: {
        pageTitle: "Standards",
        breadcrumb: [
          {
            text: "Standards",
            active: true,
          },
        ],
      },
    },

    {
      path: "/standards/:id/details",
      name: "asset-details",
      component: () => import("@/views/Standards/StandardDetails.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Standard Details",
        breadcrumb: [
          {
            text: "Standards",
            to: { name: "standards" },
          },
          {
            text: "Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard/Dashboard.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Dashboard",
        // breadcrumb: [
        //   {
        //     text: 'Stats & Graphs',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/policies",
      name: "policies",
      component: () => import("@/views/Policies/Policies.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Policies",
        breadcrumb: [
          {
            text: "Policies",
            active: true,
          },
        ],
      },
    },
    {
      path: "/policies/all/:id/add",
      name: "addPolicy",
      component: () =>
        import("@/views/Policies/components/GeneratePolicyForm.vue"),
      beforeEnter: guardRoute,
      props: false,
      meta: {
        pageTitle: "Policies",
        breadcrumb: [
          {
            text: "Policies",
            to: { name: "policies" },
          },
          {
            text: "Add New",
            active: true,
          },
        ],
      },
    },
    {
      path: "/policies/all/:id/details",
      name: "policiesDetails",
      component: () => import("@/views/Policies/AllPoliciesDetails.vue"),
      beforeEnter: guardRoute,
      props: false,
      meta: {
        pageTitle: "Policies",
        breadcrumb: [
          {
            text: "Policies",
            to: { name: "policies" },
          },
          {
            text: "Policy Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/policies/my/:id/details",
      name: "policiesDetails",
      component: () => import("@/views/Policies/MyPoliciesDetails.vue"),
      beforeEnter: guardRoute,
      props: false,
      meta: {
        pageTitle: "Policies",
        breadcrumb: [
          {
            text: "Policies",
            to: { name: "policies" },
          },
          {
            text: "Policy Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/periodicactivities",
      name: "periodicactivities",
      component: () => import("@/views/Periodic/PeriodicActivities.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Periodic activities",
        breadcrumb: [
          {
            text: "Periodic activities",
            active: true,
          },
        ],
      },
    },

    {
      path: "/gapassessment",
      name: "gapassessment",
      component: () => import("@/views/GapAssessment/GapAssessment.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        // pageTitle: "Gap Assessment",
        // breadcrumb: [
        //   {
        //     text: "Assessments",
        //     active: true,
        //   },
        // ],
      },
    },

    {
      path: "/gap-assessment/:id/details",
      name: "gap-assessment-details",
      component: () =>
        import(
          "@/views/GapAssessment/GapAssessmentDetails/GapAssessmentDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Gap Assessments",
            to: { name: "gapassessment" },
          },
          {
            text: "Assessment",
            active: true,
          },
        ],
      },
    },
    {
      path: "/gapassessmentfilter/:id",
      name: "gapassessmentfilter",
      component: () => import("@/views/GapAssessment/GapAssessment.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Gap Assessment",
        breadcrumb: [
          {
            text: "Assessment",
            active: true,
          },
        ],
      },
    },

    {
      path: "/gapassessment/:id/controlgapassessment",
      name: "control-gap-asssessment",
      component: () => import("@/views/GapAssessment/ControlGapAssessment.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Control Gap Assessment",
        breadcrumb: [
          {
            text: "Gap Assessment",
            to: { name: "gapassessment" },
          },
          {
            text: "Control Gap Assessment",
            active: true,
          },
        ],
      },
    },

    {
      path: "/gapassessment/gap-submit-requested-evidences",
      name: "gap-submit-requested-evidences",
      component: () =>
        import("@/views/GapAssessment/GapSubmitRequestedEvidences.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Evidence Submission",
        breadcrumb: [
          {
            text: "Gap Assessment",
            to: { name: "gapassessment" },
          },
          {
            text: "Gap Assessment Evidence Submission",
            active: true,
          },
        ],
      },
    },

    {
      path: "/vendorassessments",
      name: "vendorassessments",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/VendorAssessments/VendorAssessments.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        vendorAuth: true,
        pageTitle: "Assessments",
      },
    },

    {
      path: "/vendorassessments/:assessmentId",
      name: "vendorassessment-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/VendorAssessments/VendorAssessmentDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        vendorAuth: true,
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "vendorassessments" },
          },
          {
            text: "Assessment Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisks",
      name: "thirdpartyrisks",
      component: () => import("@/views/ThirdPartyRisks/ThirdPartyRisks.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Vendors",
        breadcrumb: [
          {
            text: "Vendors",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisks/:id/details",
      name: "thirdpartyrisks-details",
      component: () => import("@/views/ThirdPartyRisks/VendorDetails.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Vendor Details",
        breadcrumb: [
          {
            text: "Vendors",
            to: { name: "thirdpartyrisks" },
          },
          {
            text: "Vendor Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisksRA/assessments",
      name: "thirdpartyrisksRA-assessments",
      component: () =>
        import("@/views/ThirdPartyRisks/ThirdPartyRisksRA/Assessments.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessments",
      },
    },

    {
      path: "/thirdpartyrisksRA/assessments/:assessmentId/details",
      name: "thirdpartyrisksRA-assessment-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/ThirdPartyRisksRA/AssessmentDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "thirdpartyrisksRA-assessments" },
          },
          {
            text: "Assessment Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/thirdpartyrisksRA/assessments/:assessmentId/questionnaire/:questionnaireId",
      name: "thirdpartyrisksRA-assessment-questionnaire-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/ThirdPartyRisksRA/QuestionnaireDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Assessments",
            to: { name: "thirdpartyrisksRA-assessments" },
          },
          {
            text: "Assessment Details",
            to: { name: "thirdpartyrisksRA-assessment-details" },
          },
          {
            text: "Questionnaire Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisks/:id/details/assessment/:assessmentId",
      name: "thirdpartyrisks-assessments",
      component: () => import("@/views/ThirdPartyRisks/AssessmentDetails.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Vendors",
            to: { name: "thirdpartyrisks" },
          },
          {
            text: "Vendor",
            to: { name: "thirdpartyrisks-details" },
          },
          {
            text: "Assessment",
            active: true,
          },
        ],
      },
    },

    {
      path: "/thirdpartyrisks/:id/details/assessment/:assessmentId/questionnaire/:questionnaireId",
      name: "thirdpartyrisks-responses",
      // component: () => import('@/views/ThirdPartyRisks/QuestionnaireResponses.vue'),
      component: () =>
        import("@/views/ThirdPartyRisks/QuestionnaireAssessment.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Assessment Details",
        breadcrumb: [
          {
            text: "Vendors",
            to: { name: "thirdpartyrisks" },
          },
          {
            text: "Vendor",
            to: { name: "thirdpartyrisks-details" },
          },
          {
            text: "Assessment",
            to: { name: "thirdpartyrisks-assessments" },
          },
          {
            text: "Responses",
            active: true,
          },
        ],
      },
    },

    {
      path: "/questionnaires",
      name: "questionnaires",
      component: () =>
        import("@/views/ThirdPartyRisks/Questionnaires/Questionnaires.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Questionnaires",
        breadcrumb: [
          {
            text: "Questionnaires",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vendors",
      name: "vendors",
      component: () => import("@/views/Vendor/Vendors.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Third Party",
        breadcrumb: [
          {
            text: "Third Party",
            active: true,
          },
        ],
      },
    },
    {
      path: "/legalregister",
      name: "legalregister",
      component: () => import("@/views/LegalRegister/LegalRegister.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Legal Register",
        breadcrumb: [
          {
            text: "Legal Register",
            active: true,
          },
        ],
      },
    },
    {
      path: "/legalassessment/:id",
      name: "legalassessment",
      component: () => import("@/views/LegalRegister/LegalAssessment.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Legal Register Detail",
        breadcrumb: [
          {
            text: "Legal Register Detail",
            active: true,
          },
        ],
      },
    },

    {
      path: "/questionnaires/:id/details",
      name: "questionnaire-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/Questionnaires/NonImportedQuestionnaireDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Questionnaire Details",
        breadcrumb: [
          {
            text: "Questionnaires",
            to: { name: "questionnaires" },
          },
          {
            text: "Questionnaire Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/imported-questionnaires/:id/details",
      name: "imported-questionnaire-details",
      component: () =>
        import(
          "@/views/ThirdPartyRisks/Questionnaires/ImportedQuestionnaireDetails.vue"
        ),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Questionnaire Details",
        breadcrumb: [
          {
            text: "Questionnaires",
            to: { name: "questionnaires" },
          },
          {
            text: "My Questionnaire Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/incidentregister",
      name: "incidentregister",
      component: () => import("@/views/IncidentRegister/IncidentRegister.vue"),
      beforeEnter: guardRoute,
      props: true,
      meta: {
        pageTitle: "Incident Register",
        breadcrumb: [
          {
            text: "Incident Register",
            active: true,
          },
        ],
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Users/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/app-switch",
      name: "app-switch",
      props: true,
      component: () => import("@/views/AppSwitchLanding.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/2FA/:id",
      name: "TwoFA",
      component: () => import("@/views/Users/TwoFA.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "register",
      // component: () => import('@/views/Users/Register.vue'),
      component: () => import("@/views/Users/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/Users/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/Users/ResetPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/create-new-password",
      name: "create-new-password",
      props: true,
      component: () => import("@/views/Users/CreateNewPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/Settings/SettingsView.vue"),
      beforeEnter: guardRoute,
      meta: {
        pageTitle: "App Settings",
        breadcrumb: [
          {
            text: "Settings",
            active: true,
          },
        ],
      },
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/access-denied",
      name: "error-404",
      component: () => import("@/views/error/AccessDenied.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log(`From: ${from.path} --- To: ${to.path}`);
  // store.dispatch("tour/resetTours");
  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
