import Vue from "vue";
import { ToastPlugin, ModalPlugin, IconsPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import Axios from "axios";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
// Composition API
Vue.use(VueCompositionAPI);

// cross login vue cookie setup
import VueCookies from "vue-cookies";
Vue.prototype.$cookies = VueCookies;

import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";

// Setup SocketIO
/* Establish Connection */
// console.log("Socket URL",process.env.VUE_APP_SOCKETURL)
if (store.state.app.token && store.state.app.token != null) {
  const socketConnection = SocketIO(process.env.VUE_APP_SOCKETURL, {
    auth: { token: store.state.app.token },
    //Enable this while working on local environment
    // transports : ['websocket']
  });

  Vue.use(
    new VueSocketIO({
      debug: true,
      connection: socketConnection,
      vuex: { store, actionPrefix: "SOCKET_", mutationPrefix: "SOCKET_" },
      // options: { path: "/grc-dev" }
      //Optional options
    })
  );
}

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

Vue.directive("comment-format", {
  bind(el, binding) {
    const regex = /{{(.*?)\|(.*?)}}/g;
    const text = binding.value;
    el.innerHTML = text.replace(regex, (match, name, email) => {
      return `<span class="text-primary"><b>${name}</b></span> <${email}>`;
    });
  },
});

// Broadcasting to check if any tabs with TM app already exist in the browser window
window.localStorage.setItem("GRC_REQUESTING_SESSION", Date.now().toString());
window.localStorage.removeItem("GRC_REQUESTING_SESSION");

// Event Listener to Provide the current session to any requesting tab instances or to capture session sent from any other tab instances
window.addEventListener("storage", (event) => {
  // IF session dosnt exists and the Broadcasted key is GRC_SHARING_REQUESTED_SESSION then the incoming session is stored in the session storage
  if (
    event.key === "GRC_SHARING_REQUESTED_SESSION" &&
    $cookies.get("token") &&
    $cookies.get("token") != "null" &&
    $cookies.get("token") != store.state.app.token
  ) {
    if (event.newValue && event.newValue != null) {
      const session = JSON.parse(event.newValue);
      if (session && session != null && typeof session != "undefined") {
        store.commit("app/UPDATE_STATE", session);
        window.localStorage.removeItem("GRC_SHARING_REQUESTED_SESSION");
      }
    }
  }

  // Checks if GRC_REQUESTING_SESSION is being sent from other tabs & Broadcasts the session if the session exist in the current tab
  if (
    event.key === "GRC_REQUESTING_SESSION" &&
    $cookies.get("token") &&
    $cookies.get("token") != "null" &&
    store.state.app.token &&
    store.state.app.token != null &&
    store.state.app.currentWorkspace &&
    store.state.app.currentWorkspace != null &&
    !window.localStorage.getItem("GRC_SHARING_REQUESTED_SESSION")
  ) {
    let newSession = { ...store.state.app };
    newSession["isSessionProvider"] = false;
    const session = JSON.stringify(newSession);
    window.localStorage.setItem("GRC_SHARING_REQUESTED_SESSION", session);
    setTimeout(() => {
      window.localStorage.removeItem("GRC_SHARING_REQUESTED_SESSION");
    }, 5000);
  }
});

// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");

setTimeout(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}, 800);
