/* eslint-disable no-param-reassign */
<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <b-modal
      v-if="
        $store.state.app.token &&
        $store.state.app.workspaces &&
        $store.state.app.workspaces !== null
      "
      ref="my-modal"
      hide-footer
      modal-class="modal-warning"
      :no-close-on-backdrop="true"
      ok-only
      ok-variant="secondary"
    >
      <template #modal-header="{}">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5 class="text-warning mb-0">
            <feather-icon icon="AlertTriangleIcon" class="mr-25" />Warning
          </h5>
          <b-button
            @click="logout"
            size="sm"
            variant="outline-primary"
            class="m-25"
          >
            Logout</b-button
          >
        </div>
      </template>
      <div
        class="d-flex flex-column align-items-center justify-content-start mb-1"
      >
        <h5>Please choose an organization to continue.</h5>
        <v-select
          v-model="selectedCid"
          label="name"
          :options="workspaces"
          autocomplete
          style="width: 400px"
          :reduce="(wrkspc) => wrkspc.tenant_uid"
          class="mt-50"
          placeholder="--Search Organization--"
          :clearable="false"
        />
      </div>
    </b-modal>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import UserMixins from "./mixins/UserMixins";
import moment from "moment";
import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import router from "@/router";
import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ResponseMixins from "./mixins/ResponseMixins";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
import { BModal, BButton } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  data() {
    return {
      selectedCid: null,
      timer: null,
    };
  },
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BModal,
    BButton,
    vSelect,
  },

  watch: {
    selectedCid(newValue, oldValue) {
      this.hideModal();
      if (newValue && newValue != null) {
        const workspace = this.$store.state.app.workspaces.filter(
          (workspace) => workspace.tenant_uid == newValue
        );

        this.$store.dispatch("app/changetenant", workspace[0]).then(
          (response) => {
            this.handleTenantSelected();
            this.$store.dispatch("notification/getAllNotifications", {});
            // if (oldValue == null) {
            //   this.$store.dispatch("notification/getAllNotifications", {});
            //   this.subscribeNotifications();
            // }
          },
          (error) => {
            console.log("Something went wrong");
          }
        );
        // this.$store.commit("app/setTenantid", newValue);
        // this.handleTenantSelected();
      }
    },
    // $route() {
    //   if (this.$route.meta.pageTitle != "Tasks") {
    //     let coll = false;
    //     this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    //   }
    // },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  mixins: [UserMixins, ResponseMixins],
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    workspaces() {
      return this.$store.state.app.workspaces;
    },
  },
  methods: {
    load() {
      // if (
      //   this.$store.state.app.token &&
      //   this.$store.state.app.token != null &&
      //   this.$store.state.app.user.user_id &&
      //   this.$store.state.app.user.user_id != null &&
      //   this.$store.state.app.currentWorkspace.tenant_uid &&
      //   this.$store.state.app.currentWorkspace.tenant_uid != null
      // ) {
      //   this.$store.dispatch("notification/getAllNotifications", {});
      // }

      // console.log('Environment:',process.env.VUE_APP_ENVIRON)
      if (this.$store.state.app.accessToken !== null) {
        this.getCurrentUserDetails()
          .then((res) => {
            this.$store.dispatch("app/setCurrentUserDetails", res.data.data, {
              root: true,
            });
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    },

    subscribeNotifications() {
      if (
        this.$store.state.app.token &&
        this.$store.state.app.token != null &&
        this.$store.state.app.currentWorkspace &&
        this.$store.state.app.currentWorkspace != null &&
        this.$store.state.app.currentWorkspace.tenant_uid &&
        this.$store.state.app.currentWorkspace.tenant_uid != null &&
        this.$store.state.app.user.user_id &&
        this.$store.state.app.user.user_id != null
      ) {
        let channel = `grc_${this.$store.state.app.currentWorkspace.tenant_uid}_user_${this.$store.state.app.user.user_id}`;
        console.log("Socket Channel:", channel);
        this.sockets.subscribe(channel, (data) => {
          this.$store.dispatch("notification/getAllNotifications", data);
        });
      }
    },

    ...mapActions(["checkTokenExpiry"]),
    dispatchcall() {
      this.$store.dispatch("app/checkTokenExpiry");
      // console.log("am from dispatch call");
    },

    logout: function () {
      this.$store.dispatch("notification/resetNotifications");
      this.$store.dispatch("app/logout").then(() => {
        this.$router.push("/login");
      });
    },

    checkWorkspacesExist() {
      if (
        !this.workspaces ||
        this.workspaces == null ||
        typeof this.workspaces == "undefined" ||
        this.workspaces.length < 1
      ) {
        return false;
      }
      return true;
    },

    handleWorkspaceNotSelected() {
      if (
        !this.workspaces ||
        this.workspaces == null ||
        typeof this.workspaces == "undefined" ||
        this.workspaces.length < 1
      ) {
        this.$router.push("/workspaces-empty");
      } else if (this.workspaces.length == 1) {
        this.hideModal();
        this.$store.dispatch("app/changetenant", this.workspaces[0]).then(
          (response) => {
            this.handleTenantSelected();
            this.$store.dispatch("notification/getAllNotifications", {});
          },
          (error) => {
            console.log("Something went wrong");
          }
        );
      } else {
        this.showModal();
      }
    },

    checkIfRedirectable() {
      if (
        this.$store.state.app.acceptRedirectPath &&
        this.$cookies.isKey("redirectGRCPath") &&
        this.$cookies.get("redirectGRCPath") &&
        this.$cookies.get("redirectGRCPath") != "null"
      ) {
        return true;
      }
      return false;
    },

    handleRedirectPath() {
      const path = this.$cookies.get("redirectGRCPath");
      this.$cookies.set(
        "redirectGRCPath",
        null,
        null,
        null,
        process.env.VUE_APP_DOMAIN
      );
      this.$cookies.remove("redirectGRCPath");
      this.$store.commit("app/SET_ACCEPT_REDIRECT_PATH", false);
      this.$router.push(path).then((res) => {
        this.$store.dispatch("app/getCurrentUser").then(() => {
          window.location.reload();
        });
      });
    },

    handleTenantSelected() {
      if (this.checkIfRedirectable()) {
        this.handleRedirectPath();
      }
      this.$store.commit("app/SET_ACCEPT_REDIRECT_PATH", false);
      const redirectablePaths = ["/login", "/app-switch", "/workspaces-empty"];
      if (redirectablePaths.includes(this.$route.path)) {
        this.$router.push("/").then((res) => {
          this.$store.dispatch("app/getCurrentUser").then(() => {
            window.location.reload();
          });
        });
      } else {
        this.$store.dispatch("app/getCurrentUser").then(() => {
          window.location.reload();
        });
      }
    },

    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.dispatchcall();
    }, 30000);

    window.setInterval(() => {
      // Scenario when another site clears the cookie
      if (
        $cookies.get("token") &&
        $cookies.get("token") == "null" &&
        this.$store.state.app.token &&
        this.$store.state.app.token !== null
      ) {
        this.$store.dispatch("notification/resetNotifications");
        this.$store.dispatch("app/logout").then((res) => {
          this.$router.push("/login");
        });
      }
      // Scenario when the token cookie changes from another app
      if (
        $cookies.get("token") &&
        $cookies.get("token") != "null" &&
        $cookies.get("token") != this.$store.state.app.token
      ) {
        if (
          $cookies.get("uid") &&
          $cookies.get("uid") != "null" &&
          $cookies.get("uid") != this.$store.state.app.uid
        ) {
          this.$store.commit("app/AUTH_LOADING");
          this.$store.commit("app/SET_TOKEN", $cookies.get("token"));
          this.$store.dispatch("app/getCurrentUser").then((response) => {
            this.$store.commit("app/AUTH_SUCCESS", response.data.data);
            const redirectableRoutes = [
              "/login",
              "/app-switch",
              "/error-404",
              "/workspaces-empty",
              "/register",
            ];

            if (this.checkWorkspacesExist()) {
              if (redirectableRoutes.includes(this.$route.path))
                this.$router
                  .push("/")
                  .then(() => this.handleWorkspaceNotSelected());
            } else {
              this.$router.push("/workspaces-empty");
            }
          });
        } else {
          this.$store.commit("app/SET_TOKEN", $cookies.get("token"));
          // this.$store.commit(
          //   "app/SET_TENANT_ID",
          //   this.$store.state.app.tenantId
          // );
          this.$store.dispatch("app/getCurrentUser").then((res) => {
            // this.$store.commit("app/AUTH_SUCCESS", res.data.data);
            // this.handleWorkspaceNotSelected();
          });
        }
      }

      if (
        $cookies.get("token") &&
        $cookies.get("token") != "null" &&
        $cookies.get("token") == this.$store.state.app.token &&
        this.workspaces &&
        typeof this.workspaces != "undefinded" &&
        this.workspaces != null &&
        this.workspaces.length > 0 &&
        (this.$store.state.app.currentWorkspace == null ||
          this.$store.state.app.currentWorkspace.length == 0)
      ) {
        this.handleWorkspaceNotSelected();
      }

      if (
        $cookies.get("token") &&
        $cookies.get("token") != "null" &&
        $cookies.get("token") == this.$store.state.app.token &&
        $cookies.get("uid") &&
        $cookies.get("uid") != "null" &&
        $cookies.get("uid") == this.$store.state.app.uid &&
        this.workspaces &&
        typeof this.workspaces != "undefinded" &&
        this.workspaces != null &&
        this.workspaces.length > 0 &&
        this.$store.state.app.currentWorkspace != null
      ) {
        if (this.checkIfRedirectable()) {
          this.handleRedirectPath();
        }
        const redirectableRoutes = [
          "/login",
          "/error-404",
          "/no-workspaces",
          "/register",
          "/app-switch",
        ];
        if (redirectableRoutes.includes(this.$route.path)) {
          this.$router.push("/");
        }
      }
    }, 1000);

    this.load();
  },

  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },

  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },

  beforeDestroy() {
    clearInterval(this.timer);
    let channel = `grc_${this.$store.state.app.currentWorkspace.tenant_uid}_user_${this.$store.state.app.user.user_id}`;
    this.sockets.unsubscribe(channel);
    console.log("Un-Subscribed from channel", channel);
    this.$store.commit("app/SET_CID", null);
    this.$store.commit("app/SET_TENANT_ID", null);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
