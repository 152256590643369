export default {
  name: "UserMixins",

  methods: {
    mapUserType(type) {
      switch (type) {
        case "GRC_user":
          return "Consultant";

        case "Customer":
          return "Customer";

        case "Vendor":
          return "Third Party";

        case "Portal_user":
          return "Portal User";
      }
    },

    deleteUser(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/delete/user`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },


    restoreUser(params) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            url: `${process.env.VUE_APP_BASEURL}/restore/user`,
            params: params,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },

    addUserToTenant(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstname);
        bodyFormData.append("lastname", obj.lastname);
        bodyFormData.append("email", obj.email);
        bodyFormData.append("mobile", obj.mobile);
        bodyFormData.append("should_invite", obj.should_invite);
        bodyFormData.append("role_id", obj.role.selected);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/add/user`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUsers(params) {
      const newParams = {};
      Object.keys(params).forEach((key) => {
        if (params[key] && params[key] !== null && params[key] != "") {
          newParams[key] = params[key];
        }
      });

      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: newParams,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCurrentUserDetails() {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/current-user`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCurrentUserDetails(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstName);
        bodyFormData.append("lastname", obj.lastName);
        // bodyFormData.append("email", obj.email);
        bodyFormData.append("mobile", obj.mobile);
        bodyFormData.append("designation", obj.designation);
        bodyFormData.append("department_id", obj.department);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateUserDetails(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", obj.firstname);
        bodyFormData.append("lastname", obj.lastname);
        bodyFormData.append("designation", obj.designation);
        bodyFormData.append("department_id", obj.department_id);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user/${obj.userId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setUserActiveStatus(ssoId, status) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("sso_id", ssoId);
        bodyFormData.append("status", status);

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user-status`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setResourceLevelPermissions(obj) {
      console.log(obj);
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("resource_type", obj.resourceType);
        bodyFormData.append("resource_id", obj.resourceId);
        obj.permissions.map((permission, i) => {
          if (permission && permission !== undefined) {
            bodyFormData.append(`permissions[${i}]`, permission);
          }
        });

        if (obj.families && obj.families !== null && obj.families.length > 0) {
          obj.families.map((family, i) => {
            bodyFormData.append(`meta[control_family_ids][${i}]`, family.value);
          });
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/permissions/users/${obj.selectedUser}/assign-resource-permissions`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    removeResourceLevelPermissions(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("permission", obj.permissionId);
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/permissions/users/${obj.userId}/remove-user-permission`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    removeAllResourceLevelPermissions(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("resource_type", obj.resourceType);
        bodyFormData.append("resource_id", obj.resourceId);
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/permissions/users/${obj.userId}/remove-user-permissions`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getUserInviteURL(userId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/reinvite-user-link`,
          params: { user_id: userId },
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    resetLoggedInUserPassword(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("current_password", obj.currentPassword);
        bodyFormData.append("password", obj.password);
        bodyFormData.append("password_confirmation", obj.confirm);
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/update-user-password`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
